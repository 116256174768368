<template>
    <div class="content-main">
        <div class="content-header">
            <h1>产品信息</h1>
        </div>
        <div>
            <el-divider></el-divider>
        </div>
        <div class="content-nav">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
                <el-breadcrumb-item>产品信息</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <!--切换tab-->
        <div class="tab-content">
            <!--tab-->
            <div class="tab-list">
                <div :class="bindTabClass.typeAll" @click="handleClick(0)">全部</div>
                <div :class="bindTabClass.type1" @click="handleClick(1)">补充包</div>
                <div :class="bindTabClass.type2" @click="handleClick(2)">预组</div>
                <div :class="bindTabClass.type3" @click="handleClick(3)">其他</div>
            </div>
            <!--select-->
            <div class="tab-select">
                <select @change="handleSearchChange" v-model="search.key">
                    <option value="">请选择国家/集团</option>
                    <optgroup label="国家">
                        <option value="王冠圣域">王冠圣域</option>
                        <option value="龙族帝国">龙族帝国</option>
                        <option value="布兰特之门">布兰特之门</option>
                        <option value="暗邦">暗邦</option>
                        <option value="基元">基元</option>
                        <option value="圣律诗院">圣律诗院</option>
                    </optgroup>
                    <optgroup label="集团">
                        <option value="">敬请期待</option>
                    </optgroup>
                </select>
            </div>
            <div></div>
        </div>
        <!--列表-->
        <div class="product-list">
            <div class="product-list-content">
                <ProductItem v-for="item in dataList" :key="item.id" :info="item"
                             @click.native="$router.push({path:'/index/productInfo',query:{id:item.id}})"
                >
                </ProductItem>
            </div>
        </div>
    </div>
</template>

<script>
    import ProductItem from "./components/product-item"

    export default {
        name: "list",
        components:{ProductItem},
        data() {
            return {
                bindTabClass : {
                    typeAll:"tab-selected",
                    type1:"",
                    type2:"",
                    type3:"",
                },
                dataList:[],
                search:{
                    type:0,
                    key:""
                }
            }
        },
        methods: {
            handleClick(type) {
                switch (type) {
                    case 0:
                        this.bindTabClass = {
                            typeAll:"tab-selected",
                            type1:"",
                            type2:"",
                            type3:"",
                        };
                        break;
                    case 1:
                        this.bindTabClass = {
                            typeAll:"",
                            type1:"tab-selected",
                            type2:"",
                            type3:"",
                        };
                        break;
                    case 2:
                        this.bindTabClass = {
                            typeAll:"",
                            type1:"",
                            type2:"tab-selected",
                            type3:"",
                        };
                        break;
                    case 3:
                        this.bindTabClass = {
                            typeAll:"",
                            type1:"",
                            type2:"",
                            type3:"tab-selected",
                        };
                        break;
                    default:
                        break;
                }

                this.search.type = type;
                this.getList();
            },
            getList(){
                this.$axios.post("/site/siteProducts/getIndexProductList",this.search)
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.dataList = res.data.data;
                        }else{
                            this.$message.error(res.data.msg)
                        }
                })
            },
            handleSearchChange() {
                console.log(this.search.key);
                this.getList();
            }
        },
        created() {
            this.getList();
        }

    }
</script>

<style scoped>
    .content-main{
        text-align: center;
        background-color: #ffffff;
    }
    .content-nav{
        width: 100%;
        margin: 0 auto;
    }
    .content-header{
        margin-top: 30px;
    }
    .tab-content{
        width: 100%;
        height: auto;
        background-color: #ffffff;
        padding: 30px 0px;
    }
    .tab-list{
        text-align: center;
        border-bottom: 1px solid #111111;
    }
    .tab-list >div{
        display: inline-block;
        height: 50px;
        font-size: 22px;
        line-height: 50px;
        border: 1px solid #111111;
        border-bottom:none;
        width: 22%;
        margin: 0 4px;
    }
    .tab-list >div:hover{
        color: #ffffff;
        background-color: #111111;
        cursor: pointer;
    }
    .tab-selected{
        color: #ffffff;
        background-color: #111111;
    }

    .tab-select{
        margin-top: 30px;
        text-align: right;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }
    .tab-select >select{
        height: 34px;
        width: 200px;
        border: 2px solid #eeeeee;
    }
    .product-list{
        background-color: #f0f0f0;
        height: auto;
        padding: 10px 0;
    }
    .product-list-content{
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }
    .product-list-content >div{
        width: 46%;
       /* min-width: 330px;*/
        display: inline-block;
        vertical-align:middle;
        margin: 3px;
    }
    /*@media screen and (max-width: 768px){
        .product-list-content{
            width: 100%;
        }
    }*/

</style>
